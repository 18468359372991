import $ from "jquery";
import "slick-carousel";

function initSliderNav($slider) {
  let $content = $slider.find(".js-slide__content");
  const $prev = $slider.find(".js-prev-button");
  const $next = $slider.find(".js-next-button");
  
  
  if ($content.find(".slider-nav").length === 0) {
    $content.append('<div class="slider-nav"></span></div>');
  }
  
  const $sliderNav = $content.find(".slider-nav");
  $sliderNav.append($prev);
  $sliderNav.append($next);
}

function slickSlider($slider) {
  
  $slider.slick({
    arrows: true,
    dots: false,
    fade: true,
    infinite: true,

    cssEase: "linear",
    prevArrow:
      '<button type="button" class="slick-prev js-prev-button"></button>',
    nextArrow:
      '<button type="button" class="slick-next js-next-button"></button>',
  });

  initSliderNav($slider);
}

// Initializing each slider when the DOM is ready
$(document).ready(function ($) {
  $(".js-new-slider").each(function () {
    slickSlider($(this));
  });
});
